import queryString from 'query-string';
import React from 'react';

import { QuestionsTenant } from '../components';
import SEO from '../components/seo';

const TenantSatisfactionSurvey = (props) => {
  let param = queryString.parse(props.location.search);

  return (
    <>
      <SEO title="Pesquisa de Satisfação – Inquilinos" />
      <QuestionsTenant idPerson={param.z} />
    </>
  );
};

export default TenantSatisfactionSurvey;
